import axios from "axios";
import { atom, useAtom } from "jotai";
import cloneDeep from "lodash.clonedeep";
import { traverse } from "object-traversal";
import { useEffect, useRef, useState } from "react";
import sift from "sift";
import toastr from "toastr";
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";

/**
 *
 * products
 *
 *
 */

// THIS IS THE GLOBAL products DATA OBJECT
export const products_state = atom({
  activeId: null,
  activeObj: null,
  array: null,
  isPrimed: false,
});

// function to update products_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_products_activeId = atom(null);

// holds the fetched product array
const all_products_array = atom(null);

// *
// plates are referenced in product objects
// *
const all_plates_array = atom(null);

/**
 *
 * components
 *
 */

// THIS IS THE GLOBAL components DATA OBJECT
export const components_state = atom({
  activeId: null,
  activeObj: null,
  array: null,
  isPrimed: false,
});

// used in UrlDataController
export const default_component_id = "baseColor_default";

// function to update components_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_components_activeId = atom(null);

// holds the fetched components array
const all_components_array = atom(null);

// *
// sections are referenced in components objects
// *
const all_sections_array = atom(null);

/**
 *
 * items
 *
 */

// THIS IS THE GLOBAL items DATA OBJECT
export const items_state = atom({
  activeIds: null,
  activeObjs: null,
  array: null,
  isPrimed: false,
});

// function to update items_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_items_activeIds = atom(null);

// holds the fetched items array
const all_items_array = atom(null);

// holds the fetched items_list arrays
const items_list_arrays = atom(null);

/**
 *
 *
 * MISC
 *
 *
 */

// fitment (coming from MGP wordpress site)
const fitment_obj = atom(null);

// used to make the dynamic engraving textures on caliper covers
export const engraving_texture_obj_front = atom(null);
export const engraving_texture_obj_rear = atom(null);

// distinguishes between "standalone" mode and "drive" mode
const mode_state_init = atom("drive");
export const mode_state = atom(
  (get) => get(mode_state_init),
  (get, set, newMode) => {
    set(mode_state_init, newMode);
    set(is_driving, false); // auto update is_driving to false when mode state is changed
  }
);
// is the drive animation active or not
export const is_driving = atom(false);

// loading state for when images are loading in the DOM (CanvasCompositor) that will be used in the 3D scene
export const loading_state = atom(false);

// handles the material of the rim in drive mode since it can be switched by shopper
export const rim_material = atom("rim_chrome_shiny");
// displays rim materials in build section
export const is_rim_section_active = atom(false);

// used to store a base64 image of the canvas to send to shopping cart
export const canvas_base64 = atom(null);

// create Session Id for this runtime instance that can be used for us to identify errors
const { v4: uuidv4 } = require("uuid");
export const session_id = atom(uuidv4());

// data passed in from the UrlDataController that determines activeId's
export function GlobalDataManagers({
  // products
  products_activeId_fromURL,
  update_products_activeId_inURL,
  // components
  components_activeId_fromURL,
  update_components_activeId_inURL,
  // items
  items_activeIds_fromURL,
  update_items_activeIds_inURL,
}) {
  /**
   *
   * Load the data required to initialize the experience
   *
   */

  const [isInitDataLoaded, setIsInitDataLoaded] = useState(false);
  useEffect(() => {
    loadInitData();
  }, []);
  async function loadInitData() {
    await Promise.all([
      loadFitmentObj(),
      loadMakesArray(),
      // loadProductData(),
      loadPlatesData(),
      loadComponentsData(),
      loadSectionsData(),
      loadItemsData(),
      loadItemsListData(),
    ]);
    setIsInitDataLoaded(true);
  }

  /**
   *
   * fitment (coming from MGP wordpress site)
   *
   *
   */

  const [fitmentObj, setfitmentObj] = useAtom(fitment_obj);
  const defaultFitment = {
    year: "2004",
    make: "BMW",
    model: "Z4",
    option: "Roadster",
    sub_model: "3.0i",
    plate_code_front: "F2",
    plate_code_rear: "R2",
    plate_number_front: "S197-F-LC",
    plate_number_rear: "C501-R-LC",
    option_link: "/car/bmw/z4/2004/3.0i/",
    set_count: 4,
    part_number: "22015S",
    base_price: 249,
    pass_through: {
      id: "4571",
      make: "BMW",
      model: "Z4",
      option: "Roadster",
      plate_code_front: "F2",
      plate_code_rear: "R2",
      plate_number_front: "S197-F-LC",
      plate_number_rear: "C501-R-LC",
      submodel: "3.0i",
      year: "2004",
    },
  };

  async function loadFitmentObj() {
    let fitmentObj_wordpress = window._tt?.fitmentData || undefined;

    if (fitmentObj_wordpress) {
      Object.keys(fitmentObj_wordpress).map(function (key) {
        // format the data to match case of our data
        if (key === "make" || key === "model" || key === "sub_model") {
          fitmentObj_wordpress[key] = fitmentObj_wordpress[key].toLowerCase();
        } else if (key.includes("plate_code")) {
          fitmentObj_wordpress[key] = fitmentObj_wordpress[key].toUpperCase();
          // handle case where R- is prepended to the plate code and we need it removed
          if (fitmentObj_wordpress[key].includes("R-")) fitmentObj_wordpress[key] = fitmentObj_wordpress[key].slice(2);
        }
      });
    } else {
      // set default fitment obj
      fitmentObj_wordpress = defaultFitment;
      window._tt = {
        fitmentData: fitmentObj_wordpress,
      };
    }

    console.log("____ NEW fitmentObj ______ ", fitmentObj_wordpress);

    setfitmentObj(fitmentObj_wordpress);
  }

  /**
   *
   * products
   *    also, plates are nested inside products
   *
   */

  const [productsState, setProductsState] = useAtom(products_state);
  const [requested_products_activeId, reset_update_products_activeId] = useAtom(update_products_activeId);
  const [allProductsArray, setAllProductsArray] = useAtom(all_products_array);
  const [allPlatesArray, setAllPlatesArray] = useAtom(all_plates_array);

  // all the car "makes" that will have additional engraving logo options beyond the default
  const [makesArray, setMakesArray] = useState();
  async function loadMakesArray() {
    let res = await axios("/data/makes.json");
    let allMakes = res.data;
    setMakesArray(allMakes);
  }

  // load products for experience to start
  // CUSTOM CODE: don't need this since we're generating product dynamically
  // async function loadProductData() {
  //   let res = await axios('/data/products.json');
  //   let allProducts = res.data;
  //   setAllProductsArray(allProducts);
  // }

  async function loadPlatesData() {
    let res = await axios("/data/plates.json");
    let allPlates = res.data;
    setAllPlatesArray(allPlates);
  }

  // anytime the fitment obj is updated we need to update the product _id (part_number in the data passed to us)
  useEffect(() => {
    if (isInitDataLoaded && fitmentObj) update_products_activeId_inURL(fitmentObj.part_number);
  }, [fitmentObj, isInitDataLoaded]);

  // when some child tells us to update products_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_products_activeId) {
      update_products_activeId_inURL(requested_products_activeId);
      reset_update_products_activeId(null); // reset this in case it was updated via browser back/forward btn's
    }
  }, [requested_products_activeId]);

  // when URL tells us there is a new active product, update products_state
  useEffect(() => {
    if (!isInitDataLoaded || !products_activeId_fromURL) return;
    let productsArray = generateProductObj(); // custom code
    // let productsArray = filterProductData();
    let activeProduct = productsArray.find((productObj) => {
      return productObj._id === products_activeId_fromURL;
    });
    let newProductsState = {
      activeId: products_activeId_fromURL,
      activeObj: activeProduct,
      array: productsArray,
      isPrimed: true,
    };
    setProductsState(newProductsState);
    console.log(`____ NEW productsState ______`, newProductsState);
  }, [products_activeId_fromURL, isInitDataLoaded]);

  // custom code for MGP so we can dynamically generate product data instead of syncing our data with the clients
  function generateProductObj() {
    // the obj we are generating
    let productObj = {};

    // format the car "make", which will match our component id's and logo paths (undercase && spaces turn into -)
    let carMake = fitmentObj.make.toLowerCase().replace(" ", "-");

    // derive the id of the tt engraving component using the makesArray
    let engravingComponentName = makesArray.includes(carMake) ? `engravings_${carMake}` : "engravings_default";

    // populate the productObj with proper data
    productObj = {
      _id: fitmentObj.part_number,
      components: ["baseColor_default", engravingComponentName, "bolts_default", "shopping_cart"],
    };

    // add the fitment data to the product data and format some stuff
    productObj = { ...productObj, ...fitmentObj };
    productObj.displayName = `${fitmentObj.year} ${fitmentObj.make} ${fitmentObj.model} ${fitmentObj.sub_model}`;
    productObj.plates = {
      front: fitmentObj.plate_code_front || undefined,
      rear: fitmentObj.plate_code_rear || undefined,
    };

    // inject correct plate objects
    let plateId_f;
    let plateId_r;
    if (productObj.plates.front) {
      plateId_f = productObj.plates.front;
      productObj.plates.front = allPlatesArray.find((plateObj) => plateObj._id === plateId_f);
    }
    if (productObj.plates.rear) {
      plateId_r = productObj.plates.rear;
      productObj.plates.rear = allPlatesArray.find((plateObj) => plateObj._id === plateId_r);
    }
    // check if plate is a kind we don't support
    // all are supported as of launch 05/22, but we need to make sure we know if new ones are added by MGP
    const supportedPlateIds = ["F1", "F2", "F3", "F4", "R1", "R2", "R3", "R4"];
    if (plateId_f && !supportedPlateIds.includes(plateId_f)) {
      AlertSlackOfError("generateProductObj in GlobalDataManagers.js", `Unsupported front plate id: ${plateId_f}`);
    }
    if (plateId_r && !supportedPlateIds.includes(plateId_r)) {
      AlertSlackOfError("generateProductObj in GlobalDataManagers.js", `Unsupported rear plate id: ${plateId_r}`);
    }

    // even though it's only 1 product obj, we'll return it in an array so other code doesn't have to change to accomodate this custom logic
    return [productObj];
  }

  // // filter and return an array with the active product
  // function filterProductData() {
  //   let applicableProducts = allProductsArray.filter(sift({ _id: { $in: [products_activeId_fromURL] } }));
  //   // inject fitment data into product
  //   applicableProducts = applicableProducts.map(productObj => {
  //     productObj = {...productObj, ...fitmentObj};
  //     productObj.displayName = `${fitmentObj.year} ${fitmentObj.make} ${fitmentObj.model} ${fitmentObj.sub_model}`;
  //     productObj.plates = {
  //       front: fitmentObj.plate_code_front,
  //       rear: fitmentObj.plate_code_rear || undefined
  //     }
  //     return productObj;
  //   });
  //   // inject correct plate objects
  //   applicableProducts = applicableProducts.map(productObj => {
  //     let plateId_f = productObj.plates.front;
  //     productObj.plates.front = allPlatesArray.find((plateObj) => plateObj._id === plateId_f);
  //     if (productObj.plates.rear) {
  //       let plateId_r = productObj.plates.rear;
  //       productObj.plates.rear = allPlatesArray.find((plateObj) => plateObj._id === plateId_r);
  //     }
  //     return productObj;
  //   });
  //   return applicableProducts;
  // }

  /**
   *
   * components
   *    also, sections are nested inside components
   *    also, items/items_list are nested inside components
   *
   */

  const [componentsState, setComponentsState] = useAtom(components_state);
  const [requested_components_activeId, reset_update_components_activeId] = useAtom(update_components_activeId);
  const [allComponentsArray, setAllComponentsArray] = useAtom(all_components_array);
  const [allSectionsArray, setAllSectionsArray] = useAtom(all_sections_array);
  const [itemsListArrays, setItemsListArrays] = useAtom(items_list_arrays);
  const allComponentsArray_ref = useRef();

  // load components for experience to start
  async function loadComponentsData() {
    let res = await axios("/data/components.json");
    let allComponents = res.data;
    allComponentsArray_ref.current = allComponents;
    setAllComponentsArray(allComponents);
  }
  async function loadSectionsData() {
    let res = await axios("/data/sections.json");
    let allSections = res.data;
    setAllSectionsArray(allSections);
  }

  // when some child tells us to update components_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_components_activeId) {
      update_components_activeId_inURL(requested_components_activeId);
      reset_update_components_activeId(null); // reset this in case it was updated via browser back/forward btn's
    }
  }, [requested_components_activeId]);

  // when active product changes, we tell the URL to update the active component to be the product's first component
  // unless active component is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!productsState.activeId || components_activeId_fromURL) return;
    update_components_activeId_inURL(productsState.activeObj.components[0]);
  }, [productsState.activeId]);

  // when URL tells us there is a new active component, update components_state
  useEffect(() => {
    if (!components_activeId_fromURL || !productsState.isPrimed) return;
    let componentChoicesArray = filterComponentData();
    let activeComponent = componentChoicesArray.find((componentObj) => componentObj._id === components_activeId_fromURL);
    let newComponentsState = { activeId: components_activeId_fromURL, activeObj: activeComponent, array: componentChoicesArray, isPrimed: true };
    setComponentsState(newComponentsState);
    console.log(`____ NEW componentsState ______`, newComponentsState);
  }, [components_activeId_fromURL, productsState.isPrimed]);

  // filter and return an array with the list of components
  function filterComponentData() {
    let applicableComponentIds = productsState.array.find((productObj) => productObj._id === productsState.activeId).components;
    let applicableComponents = allComponentsArray.filter(sift({ _id: { $in: applicableComponentIds } }));
    // make applicableComponents have same order as product.components array
    applicableComponents.sort((a, b) => {
      return applicableComponentIds.indexOf(a._id) - applicableComponentIds.indexOf(b._id);
    });
    // no need to update sections and items again
    if (componentsState.isPrimed) return applicableComponents;
    // inject correct sections objects and items_list id's
    applicableComponents.forEach((componentObj) => {
      // sections
      let sectionIds = componentObj.sections;
      sectionIds.forEach((sectionId, index) => {
        componentObj.sections[index] = allSectionsArray.find((sectionObj) => sectionObj._id === sectionId);
      });
      // items_list
      let expandedItemsArray = [];
      componentObj.items.forEach((itemId, index) => {
        // expand any _items_list
        if (itemId.includes("_items_list")) {
          let itemsArray_fromList = itemsListArrays[itemId];
          expandedItemsArray = expandedItemsArray.concat(itemsArray_fromList);
        } else {
          expandedItemsArray.push(itemId);
        }
      });
      // update component's .items
      componentObj.items = expandedItemsArray;
    });
    return applicableComponents;
  }

  /**
   *
   * items
   *
   */

  const [itemsState, setItemsState] = useAtom(items_state);
  const [requested_items_activeIds, reset_update_items_activeId] = useAtom(update_items_activeIds);
  const [allItemsArray, setAllItemsArray] = useAtom(all_items_array);
  const allItemsArray_ref = useRef();

  function defaultItemActiveIds() {
    let activeIds = {};
    componentsState.array.forEach((componentObj) => {
      if (componentObj._id === "baseColor_default") {
        activeIds[componentObj._id] = { _id: "red_powder_coat" };
      } else if (componentObj._id === "bolts_default") {
        activeIds[componentObj._id] = { bolts: { _id: "bolts_off" }, color: { _id: "silver" } };
      } else if (componentObj._id.includes("engraving")) {
        activeIds[componentObj._id] = {
          front: { engraving: { _id: "MGP" }, color: { _id: "silver" } },
          rear: { engraving: { _id: "MGP" }, color: { _id: "silver" } },
        };
        // handle case when there's only one plate
        if (!fitmentObj.plate_code_rear) activeIds[componentObj._id].rear = undefined;
        if (!fitmentObj.plate_code_front) activeIds[componentObj._id].front = undefined;
      }
    });
    return activeIds;
  }

  // load items for experience to start
  async function loadItemsData() {
    let res = await axios("/data/items.json");
    let allItems = res.data;
    allItemsArray_ref.current = allItems;
    setAllItemsArray(allItems);
  }
  // load items_list for experience to start
  async function loadItemsListData() {
    let [color_items_list, engravings_default_items_list] = await Promise.all([loadColorItems(), loadEngravingItems()]);
    setItemsListArrays({
      color_items_list: color_items_list,
      engravings_default_items_list: engravings_default_items_list,
    });
  }
  async function loadColorItems() {
    let res = await axios("/data/items_list/color_items_list.json");
    let array = res.data;
    return array;
  }
  async function loadEngravingItems() {
    let res = await axios("/data/items_list/engravings_default_items_list.json");
    let array = res.data;
    return array;
  }

  // when some child tells us to update items_state.activeIds, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_items_activeIds) {
      update_items_activeIds_inURL(requested_items_activeIds);
      reset_update_items_activeId(null); // reset this in case it was updated via browser back/forward btn's
    }
  }, [requested_items_activeIds]);

  // when active product changes & components_state is primed, we tell the URL to update the activeIds to each component's default item
  // happens on site load unless activeIds is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!componentsState.isPrimed || items_activeIds_fromURL) return;
    update_items_activeIds_inURL(defaultItemActiveIds());
  }, [productsState.activeId, componentsState.isPrimed]);

  // CUSTOM CODE:
  // if the lit item is active for the baseColor_default component but the texture src was a temp one, reset everything back to default
  useEffect(() => {
    if (!itemsState.isPrimed) return;
    if (
      itemsState.activeIds.baseColor_default._id === "lit_baseColor" &&
      itemsState.activeIds.baseColor_default.inputs.uploaded_logo_src.slice(0, 5).includes("temp") &&
      !itemsState.activeObjs.baseColor_default.uploaded_logo_base64
    )
      update_items_activeIds_inURL(defaultItemActiveIds());
  }, [itemsState.isPrimed]);

  // when URL tells us there is a new items.activeIds, update items_state
  useEffect(() => {
    if (!items_activeIds_fromURL || !componentsState.isPrimed) return;
    let items_activeIds_clone = cloneDeep(items_activeIds_fromURL);
    let newItemsArray = getUpdatedItemsArray(items_activeIds_clone);
    newItemsArray = customUpdateToItems(newItemsArray); // custom code for MGP
    let newActiveObjs = getActiveItemObjs(items_activeIds_clone, newItemsArray);
    let newItemsState = { activeIds: items_activeIds_fromURL, activeObjs: newActiveObjs, array: newItemsArray, isPrimed: true };
    setItemsState(newItemsState);
    console.log(`____ NEW itemsState ______`, newItemsState);
  }, [items_activeIds_fromURL, componentsState.isPrimed]);

  function customUpdateToItems(itemsArray) {
    const litItem = itemsArray.find((item) => item._id === "lit_baseColor");
    if (litItem?.uploaded_logo_src?.slice(0, 5).includes("http")) {
      litItem.material_obj.properties.map = litItem.uploaded_logo_src;
    }
    return itemsArray;
  }

  function getActiveItemObjs(activeIds, itemsArray) {
    const unavailableItems = [];
    let activeObjs = { ...activeIds };
    let mods = {};

    const updateObjViaPaths = (objToUpdate, mods) => {
      for (var path in mods) {
        var k = objToUpdate;
        var steps = path.split(".");
        steps.pop(); // removing the _id entry so we replace whole object
        var last = steps.pop();
        steps.forEach((e) => (k[e] = k[e] || {}) && (k = k[e]));
        k[last] = mods[path];
      }
      return objToUpdate;
    };

    traverse(activeObjs, (context) => {
      let { key, value, meta } = context;
      if (key === "_id") {
        // we've found an abbreviated item obj
        // add its path and the full item obj to mods
        mods[meta.currentPath] = { ...itemsArray.find((item) => item._id === value) };
        // check for edge case where old item id's are saved in URL
        const isEmpty = Object.keys(mods[meta.currentPath]).length === 0;
        if (isEmpty) {
          mods[meta.currentPath] = getReplacementItem(meta.currentPath.split(".")[0], value);
          unavailableItems.push({
            componentId: meta.currentPath.split(".")[0],
            unavailableItemId: value,
            newItemId: mods[meta.currentPath]._id,
          });
        }
      }
    });

    if (unavailableItems.length > 0) {
      // alert user of missing items
      unavailableItemAlerts([...new Set(unavailableItems.map((item) => item.unavailableItemId))]);
      // update active ids in URL
      let newActiveIds = { ...activeIds };
      unavailableItems.forEach((obj) => {
        newActiveIds[obj.componentId]._id = obj.newItemId;
      });
      update_items_activeIds_inURL(newActiveIds);
    }

    activeObjs = updateObjViaPaths(activeObjs, mods);
    return activeObjs;
  }

  function unavailableItemAlerts(unavailableItems) {
    unavailableItems?.forEach((item) => {
      const title = item
        .replace(/color/g, "")
        .replace(/(pattern).*$/g, "$1") // remove anything after "pattern"
        .replace(/-/g, " ")
        .replace(/_/g, " ")
        .replace(/\s+/g, " "); // remove any double spaces
      toastr.info(`"${title}" is unavailable`);
    });
  }

  // traverses activeIds obj and finds any inputs
  // uses those inputs to update item in itemsArray
  function getUpdatedItemsArray(activeIds) {
    let itemsArrayCopy;
    if (!itemsState.array) itemsArrayCopy = getApplicableItemsArray();
    else itemsArrayCopy = itemsState.array;

    traverse(activeIds, (context) => {
      const { parent, key, value, meta } = context;
      if (key === "inputs" && value) {
        let item = itemsArrayCopy.find((obj) => obj._id === parent._id);
        updateItemWithInputs(item, value);
      }
    });
    return itemsArrayCopy;
  }

  function getApplicableItemsArray() {
    // make array of all applicable item id's
    let itemIdArray = [];
    componentsState.array.forEach((component) => {
      itemIdArray = itemIdArray.concat(component.items);
    });
    // TODO: custom for MGP project
    // ---------------------------
    itemIdArray = itemIdArray.concat([
      "rim_chrome_shiny",
      "rim_chrome_matte",
      "rim_white",
      "rim_grey_matte",
      "rim_grey_shiny",
      "rim_black_shiny",
      "rim_black_matte",
    ]);
    // ---------------------------
    // filter the allItemsArray to only include the applicable ones according to itemIdArray
    return allItemsArray.filter(sift({ _id: { $in: itemIdArray } }));
  }

  // updates the item specified with the new inputs
  function updateItemWithInputs(item, newInputObj) {
    // pasting the newInputObj to item.inputs
    item.inputs = newInputObj;
    // traverse new inputs
    Object.entries(newInputObj).forEach(([inputKey, inputValue]) => {
      // traverse item
      traverse(item, (context) => {
        const { parent, key, value, meta } = context;
        // update item values with input values
        if (inputKey == key && !meta.currentPath.includes("inputs") && inputValue !== null) {
          parent[key] = inputValue;
        }
      });
    });
  }

  function getDefaultItemFromComponent(componentId) {
    let itemId = allComponentsArray_ref.current?.find((componentObj) => componentObj._id === componentId)?.items[0];
    let itemObj = allItemsArray_ref.current?.find((item) => item._id === itemId);
    return itemObj;
  }

  // CUSTOM CODE: handles the edge case where an old item id is being used and needs to be swapped for a new one
  function getReplacementItem(componentId, oldItemId) {
    let newItemId;
    switch (oldItemId) {
      case "FO075EST---ST1_No_Bolts_-_STO_With_Bolts":
        newItemId = "FO075EST_-_STO_With_Bolts";
        break;

      default:
        newItemId = getDefaultItemFromComponent(componentId)._id;
        break;
    }
    let itemObj = allItemsArray.find((item) => item._id === newItemId);
    return itemObj;
  }

  return null;
}
