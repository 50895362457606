import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTransition, animated } from "react-spring";
import PropTypes from "prop-types";
import "./cartStyles.scss";

export const CardElementCart = ({ cardContentArray, imgFrame = true, imgFrameColor = "#E9EDF0", cardsPerRow = 1 }) => {
  const layoutInfo = cardLayoutFactory(cardsPerRow);

  // returns an array with animation style data on each item
  const dataToDisplayTransitionArray = useTransition(cardContentArray, {
    trail: 100,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  return (
    <Row>
      {dataToDisplayTransitionArray((style, card, key, index) => {
        const componentClass = componentClassFactory(card.section);

        let litThumbnailSrc = null;
        if (card._id == "lit_baseColor") {
          litThumbnailSrc = card.uploadedLogoSrc?.slice(0, 5).includes("temp") ? card.uploadedLogoBase64 : card.uploadedLogoSrc;
        }

        // handles case of cartObj that is present when a fill color is chosen that requires an additional_price
        if (card.isAdditionalPriceForColor) {
          return (
            <Col xs={layoutInfo.xs} md={layoutInfo.md} key={card.price}>
              <animated.div style={style}>
                <Card className={`cartCard mx-auto mx-2 mb-3 ${componentClass}`} style={{ width: layoutInfo.width, color: "black", minHeight: "0px" }}>
                  <div className="redLinearGradient"></div>
                  <Card.Body className="py-2 d-flex flex-column">
                    <Card.Title className="mt-auto" style={{ color: "black" }}>
                      Premium Color on Bolts or Engraving
                    </Card.Title>
                    {card.price !== 0 && <p className="price mb-0">{`$${isNumber(card.price) ? parseFloat(card.price).toFixed(2) : "N/A"}`}</p>}
                  </Card.Body>
                </Card>
              </animated.div>
            </Col>
          );
        }

        return (
          <Col xs={layoutInfo.xs} md={layoutInfo.md} key={card._id}>
            <animated.div style={style}>
              <Card
                className={`cartCard mx-auto mx-2 mb-3 ${componentClass} ${card._id.includes("custom_logo") && "customLogo"}`}
                style={{ width: layoutInfo.width, color: card?.customData?.cardColor ? "#fafafa" : "black" }}
              >
                <div className="redLinearGradient"></div>
                <div className={`d-flex my-3 imgContainer ${imgFrame ? "imgFrame" : ""}`} style={{ backgroundColor: imgFrame && imgFrameColor }}>
                  {litThumbnailSrc && <Card.Img className={`mx-auto`} alt="primary image" variant="top" src={litThumbnailSrc} />}
                  {card?.previewImg && <Card.Img className={`mx-auto`} alt="primary image" variant="top" src={card.previewImg} />}
                  {card?.previewText && <h5 className="mx-auto mt-3">{card.previewText}</h5>}
                  {card?.previewCustomColor && (
                    <div className={`d-flex align-items-center mx-auto`}>
                      <span className={`colorDot m-1`} style={{ backgroundColor: card.previewCustomColor }}></span>
                    </div>
                  )}
                </div>
                <Card.Body className="py-2 d-flex flex-column">
                  <Card.Title className="mt-auto">{card.title}</Card.Title>
                  {card?.engravingType === "decalEngraving" && (
                    <Card.Title className="my-1" style={{ fontSize: "10px" }}>
                      {card._id}
                    </Card.Title>
                  )}
                  <div>
                    {card?.description && <p className="my-1">{card.description} </p>}
                    {card?.boltsColor && <p className="my-1">Color: {card.boltsColor} </p>}
                    {card?.paintName && <p className="my-1"> Paint Name: {card.paintName} </p>}
                    {card?.paintCode && <p className="my-1"> Paint Code: {card.paintCode} </p>}
                  </div>

                  {card.price !== 0 && <p className="price mb-0">{`$${isNumber(card.price) ? parseFloat(card.price).toFixed(2) : "N/A"}`}</p>}
                </Card.Body>
              </Card>
            </animated.div>
          </Col>
        );
      })}
    </Row>
  );
};

function isNumber(num) {
  if (typeof num === "number" && !isNaN(num)) {
    return true;
  }
  return false;
}

// string to be used as className for the card
const componentClassFactory = (section) => {
  switch (section) {
    case "color":
      return "colorCard";
      break;
    case "bolts":
      return "boltsCard";
      break;
    case "engravings":
      return "engravingsCard";
      break;
    default:
      return "colorCard";
      break;
  }
};

const cardLayoutFactory = (cardPerRow) => {
  switch (cardPerRow) {
    case 1:
      return { xs: 8, md: 10, width: "16rem" };
    case 2:
      return { xs: 6, md: 6 };
    case 3:
      return { xs: 4, md: 4 };
    default:
      return { xs: 8, md: 10, width: "16rem" };
  }
};

CardElementCart.propTypes = {
  /**
   * Contains card content and activeIndex
   */
  cardContentArray: PropTypes.array,
  /**
   * Whether you want white background behind the image
   */
  imgFrame: PropTypes.bool,
  /**
   * Color of the image background
   */
  imgFrameColor: PropTypes.string,
  /**
   * How many cards in a row in buildSection
   */
  cardsPerRow: PropTypes.number,
};
