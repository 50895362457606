import { useMemo } from "react";
import sift from "sift";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTransition, animated } from "react-spring";
import PropTypes from "prop-types";
import { getActiveEngravingSectionId } from "../../../modules/getActiveEngravingSectionId";
import EmbedController from "../../../../embed/EmbedController";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import "./styles.scss";

export const CardElementEngravings = ({ cardContentArray, imgFrame = true, imgFrameColor = "#E9EDF0", cardsPerRow = 1, itemsStateObj, componentsState }) => {
  const layoutInfo = cardLayoutFactory(cardsPerRow);
  const meshEditingStatus = componentsState.array[1].mesh_editing_status;
  const activeEngravingSectionId = getActiveEngravingSectionId(meshEditingStatus, itemsStateObj.state, componentsState);

  const getItems = (activeSectionArray, clickedSectionEngravingId) => {
    let frontItem, rearItem;

    if (clickedSectionEngravingId === "logo_engraving") {
      const defaultLogo = activeSectionArray.filter(sift({ default_item: { $eq: true }, section: { $eq: "logo_engraving" } }));
      frontItem = rearItem = defaultLogo[0];
    } else if (clickedSectionEngravingId === "text_engraving") {
      [frontItem, rearItem] = activeSectionArray;
    }
    // no_engraving is active
    else {
      frontItem = rearItem = activeSectionArray[0];
    }

    return { frontItem, rearItem };
  };

  // updates engravingId in itemsActiveId to the cardId that was clicked
  const handleClick = (clickedSectionEngravingId) => {
    if (clickedSectionEngravingId === activeEngravingSectionId) return;

    const activeSectionArray = itemsStateObj.state.array.filter((item) => item.section === clickedSectionEngravingId);
    const { frontItem, rearItem } = getItems(activeSectionArray, clickedSectionEngravingId);

    let copyItemsActiveIds = { ...itemsStateObj.state.activeIds };
    let applicableItemIdObj = copyItemsActiveIds[componentsState.activeId];

    if (meshEditingStatus === "both") {
      applicableItemIdObj.front.engraving = { _id: frontItem._id };
      applicableItemIdObj.rear.engraving = { _id: rearItem._id };
      // adds default inputs if not specified
      if (frontItem.inputs && !applicableItemIdObj.front.engraving.inputs) applicableItemIdObj.front.engraving.inputs = frontItem.inputs;
      if (rearItem.inputs && !applicableItemIdObj.rear.engraving.inputs) applicableItemIdObj.rear.engraving.inputs = rearItem.inputs;
      // resets color to silver (default) if no_engraving is selected
      if (frontItem._id === "no_engraving") applicableItemIdObj.front.color = { _id: "silver" };
      if (rearItem._id === "no_engraving") applicableItemIdObj.rear.color = { _id: "silver" };
    } else if (meshEditingStatus === "front") {
      applicableItemIdObj.front.engraving = { _id: frontItem._id };
      if (frontItem.inputs && !applicableItemIdObj.front.engraving.inputs) applicableItemIdObj.front.engraving.inputs = frontItem.inputs;
      if (frontItem._id === "no_engraving") applicableItemIdObj.front.color = { _id: "silver" }; // resets color to silver (default) if no_engraving is selected
    } else {
      applicableItemIdObj.rear.engraving = { _id: rearItem._id };
      if (rearItem.inputs && !applicableItemIdObj.rear.engraving.inputs) applicableItemIdObj.rear.engraving.inputs = rearItem.inputs;
      if (rearItem._id === "no_engraving") applicableItemIdObj.rear.color = { _id: "silver" }; // resets color to silver (default) if no_engraving is selected
    }

    itemsStateObj.setActiveId(copyItemsActiveIds);

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Card Engraving Type Click - MGP",
        engravingType: clickedSectionEngravingId,
        page: componentsState.activeId.split("_")[0],
      });
    }
  };

  // returns an array with animation style data on each item
  const animationOnItems = useTransition(cardContentArray, {
    trail: 100,
    initial: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    update: { enter: { opacity: 1, scale: 1 } },
    reset: true,
  });

  // prevents it from firing unless meshEditingStatus changes
  const dataToDisplayTransitionArray = useMemo(() => {
    return animationOnItems;
  }, [meshEditingStatus]);

  return (
    <Row className="my-4">
      {dataToDisplayTransitionArray((style, card, key, index) => (
        <Col xs={layoutInfo.xs} md={layoutInfo.md} key={card._id}>
          <animated.div style={style}>
            <Card
              onClick={() => handleClick(card._id)}
              className={`cardElement mx-auto mb-3 ${card._id !== activeEngravingSectionId && "disabled"}`}
              style={{ width: layoutInfo.width, color: card?.customData?.cardColor ? "#fafafa" : "black" }}
            >
              <div className={`d-flex mx-auto mb-2 ${imgFrame && "imgFrame"} zoomed`}>
                <ImgWithFallback
                  src={card.image_url}
                  fallback={`${card.image_url.split(".")[0]}.png`}
                  alt={card.displayName}
                  className="card-img-top mx-auto my-auto"
                />
              </div>
              <Card.Body className="p-0">
                <Card.Title className="text-center">{card.displayName}</Card.Title>
                {card?.description && <Card.Text>{card.description}</Card.Text>}
              </Card.Body>
            </Card>
          </animated.div>
        </Col>
      ))}
    </Row>
  );
};

const cardLayoutFactory = (cardPerRow) => {
  switch (cardPerRow) {
    case 1:
      return { xs: 8, md: 10, width: "16rem" };
    case 2:
      return { xs: 6, md: 6 };
    case 3:
      return { xs: 4, md: 4 };
    default:
      return { xs: 8, md: 10, width: "16rem" };
  }
};

CardElementEngravings.propTypes = {
  /**
   * Contains card content and activeIndex
   */
  cardContentArray: PropTypes.array,
  /**
   * Whether you want white background behind the image
   */
  imgFrame: PropTypes.bool,
  /**
   * Color of the image background
   */
  imgFrameColor: PropTypes.string,
  /**
   * How many cards in a row in buildSection
   */
  cardsPerRow: PropTypes.number,
  componentsState: PropTypes.object,
  itemsStateObj: PropTypes.object,
};
